import { useState } from 'react'
import { LayoutWrapper } from '../../shared/components/Home.styles'
import { MainLayout } from '../../shared/components/MainLayout/MainLayout'

import { Name } from '../Lead/Lead.styles'
import { Header } from '../../shared/components'
import { SearchBar } from './components/SearchBar'
import { LeadsDetailsList } from './components/LeadsDetailsList'
import { LoadingDiv, SearchBarView, TextDiv } from './LeadsList.styles'
import { Loading } from '../../shared/components/Icons'
import {
  getLeadsListByDocument,
  getLeadsListByOlxId,
  getLeadsListByRenPaId,
  getLeadsListByDocumentNumber,
} from '../../api/api'
import { toast } from 'react-toastify'
import { RequireAuth } from '../../utils/requiredAuthValidation'

const LeadsList = () => {
  const [leadsListData, setLeadsListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasSearch, setHasSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const loadLeads = async () => {
    try {
      setLoading(true)
      setHasSearch(true)

      const trimSearchValue = searchValue.replace(/^[, . -]+|[, . -]+$|[, . -]+/g, '').trim()
      let response = { oldFlow: [], newFlow: [] }

      const handleLongSearchValue = async (value) => {
        if (value.includes('REN-PA')) {
          const leads = await getLeadsListByRenPaId(value)
          leads?.data?.forEach((lead) => {
            response.newFlow.push(lead)
          })
        } else if (value.includes('OLX')) {
          const leads = await getLeadsListByOlxId(value)
          leads?.data?.forEach((lead) => {
            response.oldFlow.push(lead)
          })
        } else {
          const leads = await getLeadsListByOlxId(value)
          leads?.data?.forEach((lead) => {
            response.newFlow.push(lead)
          })
        }
      }

      const handleShortSearchValue = async (value) => {
        const [getLeads, getLeadsWithPreProfileAnalysis] = await Promise.all([
          getLeadsListByDocument(value),
          getLeadsListByDocumentNumber(value),
        ])

        const leadsData = getLeads?.data || []
        const leadsWithPreProfileData = getLeadsWithPreProfileAnalysis?.data || []

        if (leadsData.length === 0 && leadsWithPreProfileData.length > 0) {
          leadsWithPreProfileData.forEach((leadWithPreProfileAnalysis) => {
            response.newFlow.push(leadWithPreProfileAnalysis)
          })
        }

        if (leadsData.length > 0 && leadsWithPreProfileData.length > 0) {
          const leadWithRenCount = leadsData.filter((lead) => lead.olxId.includes('REN')).length

          if (leadWithRenCount === 0) {
            leadsData.forEach((lead) => {
              response.oldFlow.push(lead)
            })

            leadsWithPreProfileData.forEach((leadWithPreProfileAnalysis) => {
              response.newFlow.push(leadWithPreProfileAnalysis)
            })
          } else {
            processLeadsWithRen(leadsData, leadsWithPreProfileData)
          }
        }
      }

      const processLeadsWithRen = async (leads, preProfileLeads) => {
        leads.forEach(async (lead) => {
          if (lead.olxId.includes('REN')) {
            preProfileLeads.forEach((preProfileLead) => {
              const existsEqualId = preProfileLead.applicationIds.some((applicationId) =>
                applicationId.includes(lead.olxId),
              )

              if (existsEqualId) {
                response.newFlow.push(lead)
              } else {
                if (!response.newFlow.includes(lead)) {
                  response.newFlow.push(lead)
                } else if (!response.newFlow.includes(preProfileLead)) {
                  response.newFlow.push(preProfileLead)
                }
              }
            })
          } else {
            response.oldFlow.push(lead)
          }
        })
      }

      const processDecisionsPersons = () => {
        response.newFlow.forEach(async (lead) => {
          if (lead?.olxId) {
            const renPa = await getLeadsListByRenPaId(
              lead?.preProfileAnalysis?.rentalsProfileAnalysisId,
            )
            lead.preProfileAnalysis.preProfileAnalysisPersons.forEach(
              (preProfileAnalysisPerson) => {
                renPa?.data?.[0].persons.forEach((person) => {
                  if (preProfileAnalysisPerson.personId === person.personId) {
                    preProfileAnalysisPerson.decisions = person.decisions
                  }
                })
              },
            )
          }
        })
      }

      const processSearchValue = async () => {
        if (trimSearchValue.length > 11) {
          await handleLongSearchValue(searchValue)
        } else {
          await handleShortSearchValue(trimSearchValue)
        }
      }

      await processSearchValue()

      processDecisionsPersons()

      setLeadsListData(response)
    } catch (error) {
      toast.error('Ocorreu um erro inesperado! Atualize a página e tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const onInputChange = ({ target }) => {
    if (!target.value) {
      setLeadsListData([])
      return
    }
    setSearchValue(target.value)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    loadLeads()
  }

  return (
    <RequireAuth navigationRoute={'/'}>
      <LayoutWrapper>
        <MainLayout>
          <Header title='GreyZone - Análise de Credito Detalhes' />
          <SearchBarView>
            <SearchBar
              onSubmit={onSubmitHandler}
              onChange={onInputChange}
              onSearchButtonSubmit={() => loadLeads()}
            />
          </SearchBarView>
          {loading ? (
            <>
              <LoadingDiv>
                <Loading />
              </LoadingDiv>
              <TextDiv>
                <Name variant='h6'>A consulta pode demorar alguns segundos.</Name>
              </TextDiv>
            </>
          ) : (
            <LeadsDetailsList leadsDetailData={leadsListData} hasSearch={hasSearch} />
          )}
        </MainLayout>
      </LayoutWrapper>
    </RequireAuth>
  )
}

export default LeadsList
