import { Box } from '@creditas-ui/react'
import { Divider, Collapse } from 'antd'
import {
  CollapseItem,
  StyledCenter,
  StyledCollapse,
  StyledDivider,
  Item,
  Label,
  Name,
  Value,
} from '../LeadDetails.styles'
import {
  ButtonDocs,
  ContractViewerLabel,
} from '../../Lead/components/PersonItens/PersonItens.styles'
import { useState } from 'react'
import { ModalDocViewer } from '../../../shared/components/ModalDocViewer/ModalDocViewer'

const productMap = {
  RENTALS_OWNER: 'Aluguel em Dia Proprietário',
  RENTALS_TENANT: 'Aluguel em Dia Inquilino',
  RENTALS_TENANT_SPECIAL: 'Aluguel em Dia Especial Inquilino',
}

const incomeMap = {
  MONTHLY_INCOME: 'Renda Informada',
  PRESUMED_MONTHLY_INCOME: 'Renda Presumida',
  CONFIRMED_MONTHLY_INCOME: 'Renda Confirmada',
}

const resultMap = {
  APPROVED: 'Aprovado',
  REJECTED: 'Rejeitado',
}

const resultDecisionMap = {
  STATUS_REGISTRATION: 'Situação Cadastral',
  DEATH: 'Óbito',
  SIMILARITY_NAME: 'Compara Nome',
  AGE: 'Idade',
  TRUST_SCORE: 'Score DT',
  TRUST_EMAIL: 'Email',
  TRUST_PHONE: 'Telefone',
  SERASA_SCORE: 'Score Serasa',
  SERASA_SCORE_HSPN: 'Score Serasa Hspn',
  SERASA_RESTRICTIVE: 'Restritivos',
  CONSIDERED_INCOME: 'Renda Considerada',
  CALCULATED_INCOME: 'Renda Calculada',
  PRESUMED_INCOME: 'Renda Presumida',
  PRESUMED_INCOME_BVS2: 'Renda Presumida BVS2',
  CONT_SITUACAO_CADASTRAL1: 'Cont Situação Cadastral1',
  CONT_NOME1: 'Cont Nome1',
  CONT_OBITO1: 'Cont Obito1',
  CONT_IDADE1: 'Cont Idade1',
  CONT_EMAIL1: 'Cont Email1',
  CONT_TEL1: 'Cont Telelefone1',
  CONT_EMAIL2: 'Cont Email2',
  CONT_TEL2: 'Cont Telefone2',
  BLOCKLIST: 'Blocklist',
  PROFILE: 'TesteAB_Perfil_Renda_Grupo',
  QTDE_PROCESSES_CPF: 'MV_QTDE_Processos_CPF',
  QTDE_PROCESSES_CPF_1: 'MV_QTDE_Processos_CPF1',
  QTDE_PROCESSES_CPF_2: 'MV_QTDE_Processos_CPF2',
  QTDE_PROCESSES_CPF_3: 'MV_QTDE_Processos_CPF3',
  QTDE_PROCESSES_CPF_4: 'MV_QTDE_Processos_CPF4',
  QTDE_PROCESSES_CPF_5: 'MV_QTDE_Processos_CPF5',
  BLOCKLIST_EMAIL: 'Blocklist Email',
  BLOCKLIST_INCOME: 'Blocklist Renda',
  CPF_EMAIL_REPEAT: 'CPFs_Distintos_Email',
  CPF_PHONE_REPEAT: 'CPFs_Distintos_Telefone',
  EMAIL_EXISTS: 'Email Existe',
  REJECTED_REASON: 'Motivo Recusa',
  RENDITAS_INCOME: 'Renda Renditas',
  INCOME_TYPE: 'Tipo Renda',
  QUOD_INCOME: 'Renda QUOD',
  CONTROL_GROUP_RENDITAS_GREYZONE: 'Control Group Renditas Greyzone',
  BIOMETRY_DESCRIPTION: 'Descrição Biometria',
  DATE_SEND_TOKEN: 'Data Envio Token',
  DATE_TOKEN: 'Data Token',
}

const { Panel } = Collapse

export const LeadDetailsView = ({ leadDetailsData }) => {
  const [viewContract, setViewContract] = useState(false)
  const visibility = (bool) => setViewContract(bool)

  function callDocData() {
    setViewContract(true)
  }

  const LeadDetails = ({ leads }) => (
    <>
      {viewContract && (
        <ModalDocViewer
          idDoc={leads[0]?.contractIdDoc}
          viewContractBool={visibility}
          title={
            leads[0]?.profileAnalysis[0]?.persons?.find((obj) => {
              return obj.lead
            }).name
          }
        />
      )}
      {!viewContract &&
        leads.map((leadItem) => (
          <>
            <div key={leadItem?.olxId}>
              <Box>
                <br />
                <Item>
                  <Name variant='h5'> Análise: {leadItem?.olxId}</Name>
                </Item>
              </Box>

              <Box>
                <Item>
                  <Label>Status da análise</Label>
                  <Value>{leadItem?.statusDescription}</Value>
                </Item>
                {verifyProfileDescription(leadItem) && (
                  <Item>
                    <Label>Resultado da análise de perfil</Label>
                    <Value>{verifyProfileDescription(leadItem)}</Value>
                  </Item>
                )}
                <Item>
                  <Label>Imobiliária</Label>
                  <Value>{leadItem?.realEstate?.name}</Value>
                </Item>
                <Item>
                  <Label>CNPJ Imobiliária</Label>
                  <Value>
                    {leadItem?.realEstate?.cnpj.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5',
                    )}
                  </Value>
                </Item>
                <Item>
                  <Label>Valor do pacote</Label>
                  <Value>
                    R${' '}
                    {parseFloat(
                      leadItem?.rent?.rentAmount +
                        leadItem?.rent?.condominiumAmount +
                        leadItem?.rent?.iptuAmount,
                    ).toFixed(2)}
                  </Value>
                </Item>
                <Item>
                  <Label>Crédito Limite</Label>
                  <Value>
                    {leadItem?.profileAnalysis.reduce((max, pItem) =>
                      max.version > pItem.version ? max : pItem,
                    ).creditLimit
                      ? 'R$ ' +
                        parseFloat(
                          leadItem?.profileAnalysis.reduce((max, pItem) =>
                            max.version > pItem.version ? max : pItem,
                          ).creditLimit,
                        ).toFixed(2)
                      : 'N/A'}
                  </Value>
                </Item>
                <Item>
                  <Label>Produto</Label>
                  <Value>{productMap[leadItem?.product]}</Value>
                </Item>
                {leadItem?.contractIdDoc && (
                  <Item>
                    <ButtonDocs>
                      <ContractViewerLabel onClick={() => callDocData(leadItem?.contractIdDoc)}>
                        Visualizar contrato
                      </ContractViewerLabel>
                    </ButtonDocs>
                  </Item>
                )}
              </Box>

              <StyledCollapse>
                <Panel header={'Detalhes'}>
                  <Box>
                    <CollapseItem>
                      <Label>Valor do aluguel</Label>
                      <Value>R$ {parseFloat(leadItem?.rent?.rentAmount).toFixed(2)}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Valor do Condomínio</Label>
                      <Value>R$ {parseFloat(leadItem?.rent?.condominiumAmount).toFixed(2)}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Valor do IPTU</Label>
                      <Value>R$ {parseFloat(leadItem?.rent?.iptuAmount).toFixed(2)}</Value>
                    </CollapseItem>
                    <Divider dashed />
                    <CollapseItem>
                      <Label>Rua</Label>
                      <Value>{leadItem?.rent?.streetAddress}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Bairro</Label>
                      <Value>{leadItem?.rent?.neighborhood}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Número</Label>
                      <Value>{leadItem?.rent?.streetNumber}</Value>
                    </CollapseItem>
                    {leadItem?.rent?.streetComplement && (
                      <CollapseItem>
                        <Label>Complemento</Label>
                        <Value>{leadItem?.rent?.streetComplement}</Value>
                      </CollapseItem>
                    )}
                    <CollapseItem>
                      <Label>Cidade</Label>
                      <Value>{leadItem?.rent?.city}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>CEP</Label>
                      <Value>{leadItem?.rent?.zipCode}</Value>
                    </CollapseItem>
                  </Box>
                </Panel>
              </StyledCollapse>

              <br />

              <Box>
                <Item>
                  <Name variant='h5'> Proponentes:</Name>
                </Item>
              </Box>

              {leadItem?.profileAnalysis?.map((leadProfileItem) => (
                <>
                  {leadProfileItem?.persons?.map((personItem) => (
                    <>
                      <Box>
                        <Item>
                          <Label>Nome proponente</Label>
                          <Value>{personItem?.name}</Value>
                        </Item>
                        <Item>
                          <Label>CPF</Label>
                          <Value>{personItem?.cpf}</Value>
                        </Item>
                        {personItem?.contacts?.map((personContactsItem) => (
                          <>
                            <Item>
                              <Label>
                                {personContactsItem?.channel === 'PHONE'
                                  ? 'Telefone' || personContactsItem?.channel === 'EMAIL'
                                  : 'E-mail'}
                              </Label>
                              <Value>{personContactsItem?.code}</Value>
                            </Item>
                          </>
                        ))}
                        {personItem?.incomes?.map((personIncomeItem) => (
                          <>
                            <Item>
                              <Label>{incomeMap[personIncomeItem?.type]}</Label>
                              <Value>R$ {parseFloat(personIncomeItem?.amount).toFixed(2)}</Value>
                            </Item>
                          </>
                        ))}
                        <Item>
                          <Label>Avaliação</Label>
                          <Value>{personItem?.evaluation ?? 'N/A'}</Value>
                        </Item>
                        <Item>
                          <Label>Tipo de avaliação</Label>
                          <Value>{personItem?.evaluationType ?? 'N/A'}</Value>
                        </Item>
                        <Item>
                          <StyledDivider />
                        </Item>
                      </Box>
                    </>
                  ))}
                </>
              ))}
            </div>
          </>
        ))}
    </>
  )

  const LeadDetailsNewFlow = ({ leads }) => (
    <>
      {viewContract && (
        <ModalDocViewer
          idDoc={leads[0]?.contractIdDoc}
          viewContractBool={visibility}
          title={
            leads[0]?.profileAnalysis[0]?.persons?.find((obj) => {
              return obj.lead
            }).name
          }
        />
      )}
      {!viewContract &&
        leads.map((leadItem) => (
          <>
            <div key={leadItem?.olxId || leadItem?.profileAnalysisId}>
              <Box>
                <br />
                <Item>
                  <Name variant='h5'>
                    {' '}
                    Análise: {leadItem?.olxId || leadItem?.profileAnalysisId}
                  </Name>
                </Item>
              </Box>

              <Box>
                {leadItem?.statusDescription ? (
                  <>
                    <Item>
                      <Label>Resultado da análise de perfil</Label>
                      <Value>{leadItem?.statusDescription}</Value>
                    </Item>
                  </>
                ) : (
                  <></>
                )}
                <Item>
                  <Label>Imobiliária</Label>
                  <Value>{leadItem?.realEstate?.name}</Value>
                </Item>
                <Item>
                  <Label>CNPJ Imobiliária</Label>
                  <Value>
                    {leadItem?.realEstate?.cnpj.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5',
                    )}
                  </Value>
                </Item>
                <Item>
                  <Label>Crédito Limite</Label>
                  <Value>
                    {verifyPotentialIncome(leadItem).confirmedPotential ??
                      verifyPotentialIncome(leadItem).presumedPotential ??
                      verifyPotentialIncome(leadItem).informedPotential ??
                      'N/A'}
                  </Value>
                </Item>
                {leadItem?.contractIdDoc && (
                  <Item>
                    <ButtonDocs>
                      <ContractViewerLabel onClick={() => callDocData(leadItem?.contractIdDoc)}>
                        Visualizar contrato
                      </ContractViewerLabel>
                    </ButtonDocs>
                  </Item>
                )}
                <Item>
                  <Divider dashed />
                </Item>
              </Box>

              <Box>
                <Item>
                  <Name variant='h5'> Proponentes:</Name>
                </Item>
              </Box>

              {leadItem?.preProfileAnalysis?.preProfileAnalysisPersons?.map((personItem) => (
                <>
                  <Box>
                    <Item>
                      <Label>Nome proponente</Label>
                      <Value>{personItem?.name}</Value>
                    </Item>
                    <Item>
                      <Label>CPF</Label>
                      <Value>{personItem?.documentNumber}</Value>
                    </Item>
                    <Item>
                      <Label>Telefone</Label>
                      <Value>{personItem?.phone}</Value>
                    </Item>
                    <Item>
                      <Label>Email</Label>
                      <Value>{personItem?.email}</Value>
                    </Item>
                    <Item>
                      <Label>Renda Informada</Label>
                      <Value>R$ {parseFloat(personItem?.informedIncome).toFixed(2)}</Value>
                    </Item>
                    <Item>
                      <Label>Avaliação</Label>
                      <Value>{resultMap[personItem?.result] ?? 'N/A'}</Value>
                    </Item>
                    <Item>
                      <Divider dashed />
                    </Item>
                    {personItem?.result == 'REJECTED' ? (
                      <>
                        {personItem?.rejectedReason ? (
                          <>
                            <Item>
                              <Label>Motivo de Rejeição: </Label>
                              <Value>{personItem.rejectedReason}</Value>
                            </Item>
                          </>
                        ) : (
                          <>
                            <Item>
                              <Label>
                                Motivo de Rejeição:
                                {personItem?.decisions?.map((decision) => (
                                  <>
                                    {decision?.systemResult == 'nok' ? (
                                      <>{` | ${resultDecisionMap[decision.ruleName]} |`}</>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </Label>
                            </Item>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <Item>
                      <StyledDivider />
                    </Item>
                  </Box>
                </>
              ))}

              {leadItem?.persons?.map((personItem) => (
                <>
                  <Box>
                    <Item>
                      <Label>Nome proponente</Label>
                      <Value>{personItem?.name}</Value>
                    </Item>
                    <Item>
                      <Label>CPF</Label>
                      <Value>{personItem?.documentNumber}</Value>
                    </Item>
                    <Item>
                      <Label>Telefone</Label>
                      <Value>{personItem?.phone}</Value>
                    </Item>
                    <Item>
                      <Label>Email</Label>
                      <Value>{personItem?.email}</Value>
                    </Item>
                    <Item>
                      <Label>Renda Informada</Label>
                      <Value>R$ {parseFloat(personItem?.informedIncome).toFixed(2)}</Value>
                    </Item>
                    <Item>
                      <Label>Avaliação</Label>
                      <Value>{resultMap[personItem?.result] ?? 'N/A'}</Value>
                    </Item>
                    <Item>
                      <Divider dashed />
                    </Item>
                    {personItem?.result == 'REJECTED' ? (
                      <>
                        {personItem?.rejectedReason ? (
                          <>
                            <Item>
                              <Label>Motivo de Rejeição: </Label>
                              <Value>{personItem.rejectedReason}</Value>
                            </Item>
                          </>
                        ) : (
                          <>
                            <Item>
                              <Label>
                                Motivo de Rejeição:
                                {personItem?.decisions?.map((decision) => (
                                  <>
                                    {decision?.systemResult == 'nok' ? (
                                      <>{` | ${resultDecisionMap[decision.ruleName]} |`}</>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </Label>
                            </Item>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <Item>
                      <StyledDivider />
                    </Item>
                  </Box>
                </>
              ))}
            </div>
          </>
        ))}
    </>
  )

  if (leadDetailsData.length === 0) {
    return (
      <StyledCenter>
        <Name variant='h5'>A sua pesquisa não encontrou registros correspondentes.</Name>
      </StyledCenter>
    )
  }

  const verifyProfileDescription = (profileAnalysisDetail) => {
    return (
      profileAnalysisDetail?.profileAnalysis[profileAnalysisDetail?.profileAnalysis.length - 1]
        ?.profileResultDetailedDescription ?? null
    )
  }

  const formatIncome = (amount) => {
    return amount ? `R$ ${parseFloat(amount).toFixed(2)}` : undefined
  }

  const verifyPotentialIncome = (lead) => {
    const incomeMap = {}
    const analysis = lead.preProfileAnalysis || lead

    incomeMap.confirmedPotential = formatIncome(
      analysis.confirmedPotential || analysis.confirmedPotencial,
    )
    incomeMap.presumedPotential = formatIncome(
      analysis.presumedPotential || analysis.presumedPotencial,
    )
    incomeMap.informedPotential = formatIncome(
      analysis.informedPotential || analysis.informedPotencial,
    )

    return incomeMap
  }

  return (
    <>
      <LeadDetails leads={leadDetailsData?.oldFlow} />

      <LeadDetailsNewFlow leads={leadDetailsData?.newFlow} />
    </>
  )
}
