import { Box, ButtonPrimaryTextOnly } from '@creditas-ui/react'
import { Divider } from 'antd'
import {
  StyledCenter,
  StyledDivider,
  Item,
  Label,
  Name,
  Value,
  StyledBox,
} from '../LeadsList.styles'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const LeadsDetailsList = ({ leadsDetailData, hasSearch }) => {
  let navigate = useNavigate()

  const navigateToLeadByOlxId = (olxId) => {
    navigate('/lead/' + olxId + '/details')
  }

  const resultMap = {
    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',
  }

  const resultDecisionMap = {
    STATUS_REGISTRATION: 'Situação Cadastral',
    DEATH: 'Óbito',
    SIMILARITY_NAME: 'Compara Nome',
    AGE: 'Idade',
    TRUST_SCORE: 'Score DT',
    TRUST_EMAIL: 'Email',
    TRUST_PHONE: 'Telefone',
    SERASA_SCORE: 'Score Serasa',
    SERASA_SCORE_HSPN: 'Score Serasa Hspn',
    SERASA_RESTRICTIVE: 'Restritivos',
    CONSIDERED_INCOME: 'Renda Considerada',
    CALCULATED_INCOME: 'Renda Calculada',
    PRESUMED_INCOME: 'Renda Presumida',
    PRESUMED_INCOME_BVS2: 'Renda Presumida BVS2',
    CONT_SITUACAO_CADASTRAL1: 'Cont Situação Cadastral1',
    CONT_NOME1: 'Cont Nome1',
    CONT_OBITO1: 'Cont Obito1',
    CONT_IDADE1: 'Cont Idade1',
    CONT_EMAIL1: 'Cont Email1',
    CONT_TEL1: 'Cont Telelefone1',
    CONT_EMAIL2: 'Cont Email2',
    CONT_TEL2: 'Cont Telefone2',
    BLOCKLIST: 'Blocklist',
    PROFILE: 'TesteAB_Perfil_Renda_Grupo',
    QTDE_PROCESSES_CPF: 'MV_QTDE_Processos_CPF',
    QTDE_PROCESSES_CPF_1: 'MV_QTDE_Processos_CPF1',
    QTDE_PROCESSES_CPF_2: 'MV_QTDE_Processos_CPF2',
    QTDE_PROCESSES_CPF_3: 'MV_QTDE_Processos_CPF3',
    QTDE_PROCESSES_CPF_4: 'MV_QTDE_Processos_CPF4',
    QTDE_PROCESSES_CPF_5: 'MV_QTDE_Processos_CPF5',
    BLOCKLIST_EMAIL: 'Blocklist Email',
    BLOCKLIST_INCOME: 'Blocklist Renda',
    CPF_EMAIL_REPEAT: 'CPFs_Distintos_Email',
    CPF_PHONE_REPEAT: 'CPFs_Distintos_Telefone',
    EMAIL_EXISTS: 'Email Existe',
    REJECTED_REASON: 'Motivo Recusa',
    RENDITAS_INCOME: 'Renda Renditas',
    INCOME_TYPE: 'Tipo Renda',
    QUOD_INCOME: 'Renda QUOD',
    CONTROL_GROUP_RENDITAS_GREYZONE: 'Control Group Renditas Greyzone',
    BIOMETRY_DESCRIPTION: 'Descrição Biometria',
    DATE_SEND_TOKEN: 'Data Envio Token',
    DATE_TOKEN: 'Data Token',
  }

  const LeadDetails = ({ lead }) => (
    <>
      <div key={lead?.olxId}>
        <Box>
          <br />
          <Item>
            <Name variant='h5'>Lead: {lead?.olxId}</Name>
          </Item>
          <br />
          <Item>
            <Name variant='h6'>Resultado da análise: {lead?.statusDescription}</Name>
          </Item>
          <Item>
            <Name variant='h6'>
              Data de criação: {moment(lead?.createDate, 'DD/MM/YYYY HH:mm').format('L LT')}
            </Name>
          </Item>
          <br />
        </Box>

        {lead?.profileAnalysis?.map((profileItem) =>
          profileItem?.persons?.map((personItem) => (
            <Box key={personItem?.cpf}>
              <Item>
                <Label>Nome proponente:</Label>
                <Value>{personItem?.name}</Value>
              </Item>
              <Item>
                <Label>CPF</Label>
                <Value>{personItem?.cpf}</Value>
              </Item>
              <Item>
                <Label>Avaliação</Label>
                <Value>{personItem?.evaluation ?? 'N/A'}</Value>
              </Item>
              <Item>
                <Divider dashed />
              </Item>
            </Box>
          )),
        )}

        <StyledBox>
          <ButtonPrimaryTextOnly
            data-testid='btn-new-proposal'
            variant='primary'
            onClick={() => navigateToLeadByOlxId(lead?.olxId)}
          >
            Detalhes do Lead
          </ButtonPrimaryTextOnly>
        </StyledBox>

        <Item>
          <StyledDivider />
        </Item>
      </div>
    </>
  )

  const LeadDetailsNewFlow = ({ lead }) => (
    <>
      <div key={lead?.olxId || lead?.profileAnalysisId}>
        <Box>
          <br />
          <Item>
            <Name variant='h5'> Análise de Perfil: {lead?.olxId || lead?.profileAnalysisId}</Name>
          </Item>
          <br />
          {lead?.olxId ? (
            <>
              <Item>
                <Name variant='h6'>Resultado da análise: {lead?.statusDescription}</Name>
              </Item>
            </>
          ) : (
            <></>
          )}
          <Item>
            <Name variant='h6'>
              Data de criação: {moment(lead?.createDate, 'DD/MM/YYYY HH:mm').format('L LT')}
            </Name>
          </Item>
          <br />
        </Box>

        {lead?.persons?.map((person) => (
          <>
            <Box>
              <Item>
                <Label>Nome proponente:</Label>
                <Value>{person?.name}</Value>
              </Item>
              <Item>
                <Label>CPF</Label>
                <Value>{person?.documentNumber}</Value>
              </Item>
              <Item>
                <Label>Avaliação</Label>
                <Value>{resultMap[person?.result] ?? 'N/A'}</Value>
              </Item>
              {person?.result == 'REJECTED' ? (
                <>
                  {person?.rejectedReason ? (
                    <>
                      <Item>
                        <Label>Motivo de Rejeição: </Label>
                        <Value>{person.rejectedReason}</Value>
                      </Item>
                    </>
                  ) : (
                    <>
                      <Item>
                        <Label>
                          Motivo de Rejeição:
                          {person?.decisions?.map((decision) => (
                            <>
                              {decision?.systemResult == 'nok' ? (
                                <>{` | ${resultDecisionMap[decision?.ruleName]} |`}</>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </Label>
                      </Item>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              <Item>
                <Divider dashed />
              </Item>
            </Box>
          </>
        ))}

        {lead?.preProfileAnalysis?.preProfileAnalysisPersons?.map((person) => (
          <>
            <Box>
              <Item>
                <Label>Nome proponente:</Label>
                <Value>{person?.name}</Value>
              </Item>
              <Item>
                <Label>CPF</Label>
                <Value>{person?.documentNumber}</Value>
              </Item>
              <Item>
                <Label>Avaliação</Label>
                <Value>{resultMap[person?.result] ?? 'N/A'}</Value>
              </Item>
              {person?.result == 'REJECTED' ? (
                <>
                  {person?.rejectedReason ? (
                    <>
                      <Item>
                        <Label>Motivo de Rejeição: </Label>
                        <Value>{person.rejectedReason}</Value>
                      </Item>
                    </>
                  ) : (
                    <>
                      <Item>
                        <Label>
                          Motivo de Rejeição:
                          {person?.decisions?.map((decision) => (
                            <>
                              {decision?.systemResult == 'nok' ? (
                                <>{` | ${resultDecisionMap[decision?.ruleName]} |`}</>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </Label>
                      </Item>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              <Item>
                <Divider dashed />
              </Item>
            </Box>
          </>
        ))}
      </div>

      <StyledBox>
        <ButtonPrimaryTextOnly
          data-testid='btn-new-proposal'
          variant='primary'
          onClick={() => navigateToLeadByOlxId(lead?.olxId || lead?.profileAnalysisId)}
        >
          Detalhes do Lead
        </ButtonPrimaryTextOnly>
      </StyledBox>

      <Item>
        <StyledDivider />
      </Item>
    </>
  )

  const NoResults = () => (
    <StyledCenter>
      <Name variant='h5'>A sua pesquisa não encontrou registros correspondentes.</Name>
    </StyledCenter>
  )

  if (
    leadsDetailData?.oldFlow?.length === 0 &&
    leadsDetailData?.newFlow?.length === 0 &&
    hasSearch
  ) {
    return <NoResults />
  }

  return (
    <>
      {leadsDetailData?.oldFlow?.map((item) => (
        <>
          {item?.length > 0 ? (
            <>
              {item?.map((leadDetailsItem) => (
                <LeadDetails key={leadDetailsItem?.olxId} lead={leadDetailsItem} />
              ))}
            </>
          ) : (
            <LeadDetails lead={item} />
          )}
        </>
      ))}

      {leadsDetailData?.newFlow?.map((item) => (
        <>
          {item?.length > 0 ? (
            <>
              {item?.map((leadDetailsItem) => (
                <LeadDetailsNewFlow
                  key={leadDetailsItem?.olxId || leadDetailsItem?.profileAnalysisId}
                  lead={leadDetailsItem}
                />
              ))}
            </>
          ) : (
            <LeadDetailsNewFlow lead={item} />
          )}
        </>
      ))}
    </>
  )
}
